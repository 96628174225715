var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            "max-width": "100%",
            margin: "auto",
            overflow: "hidden",
          },
          attrs: { id: "body-content-area" },
        },
        [
          _vm.isLoading ? _c("div", [_c("Loading")], 1) : _vm._e(),
          _c(
            "div",
            { class: { visible: _vm.isLoading } },
            [
              _c(
                "div",
                {
                  staticClass: "bottom-bar",
                  staticStyle: {
                    "margin-bottom": "1rem",
                    display: "flex",
                    "justify-content": "flex-end",
                  },
                  attrs: { id: "buttongroup" },
                },
                [
                  _c("div", { staticClass: "level" }, [
                    _c("div", { staticClass: "level-right" }, [
                      _c("div", { staticClass: "field is-grouped" }, [
                        _vm.selected.length === 0
                          ? _c("div", { staticClass: "control" }, [
                              _c(
                                "a",
                                {
                                  class: [
                                    "button is-accent",
                                    _vm.$hasPermissions(
                                      _vm.clientSession,
                                      ["DOCUMENT_CENTER"],
                                      2
                                    )
                                      ? ""
                                      : "disabled",
                                  ],
                                },
                                [
                                  _vm._m(0),
                                  _c(
                                    "span",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "has-text-white",
                                          attrs: {
                                            to: {
                                              name: !_vm.$hasPermissions(
                                                _vm.clientSession,
                                                ["DOCUMENT_CENTER"],
                                                2
                                              )
                                                ? ""
                                                : "DocumentCenterCreateScreen",
                                            },
                                          },
                                        },
                                        [_vm._v(" Add Document ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c("Grid", {
                style: {
                  maxHeight: _vm.maxHeight + "px",
                  height: _vm.maxHeight + "px",
                },
                attrs: {
                  "data-items": _vm.documents,
                  filters: _vm.filter,
                  sortable: true,
                  sort: _vm.sort,
                  filterable: true,
                  columns: _vm.staticColumns,
                  pageable: Object.assign({}, _vm.pageable, {
                    pageSizes: _vm.pageSizes,
                  }),
                  skip: _vm.skip,
                  take: _vm.take,
                  total: _vm.totalDocuments,
                },
                on: {
                  filterchange: _vm.filterChangeHandler,
                  sortchange: _vm.sortchange,
                  pagechange: _vm.pagechange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "SizeCellHeader",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "bcc-color",
                                attrs: {
                                  to: {
                                    name: "EditInstitution",
                                    params: {
                                      id: "" + props.dataItem.id,
                                      model:
                                        "" + JSON.stringify(props.dataItem),
                                      skip: "" + _vm.skip,
                                    },
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(props.dataItem.name) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "NameCell",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "bcc-color",
                                attrs: {
                                  to: {
                                    name: "DocumentsCenterEditScreen",
                                    params: {
                                      id: props.dataItem.id,
                                      document: props.dataItem,
                                    },
                                  },
                                },
                              },
                              [
                                _c("span", {}, [
                                  _vm._v(_vm._s(props.dataItem.displayName)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "GroupCell",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getCarrierCode(props.dataItem)) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "TypeCell",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                props.dataItem.typeCode
                                  .replace(".", "")
                                  .toUpperCase()
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "SizeCell",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c("td", { staticStyle: { "text-align": "right" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getFileSize(props.dataItem.fileSize)) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "UploadDateCell",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getUTCDate(props.dataItem.uploadDate)
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "UploadedByCell",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c("td", [
                          _vm._v(
                            " " + _vm._s(props.dataItem.uploadUserName) + " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "ActionCell",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c(
                          "td",
                          { staticStyle: { width: "100%", padding: "0" } },
                          [
                            _c("div", { staticClass: "action-cell" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.left-start",
                                      value: {
                                        content: "Download Document",
                                        container: false,
                                        classes: ["tooltip"],
                                      },
                                      expression:
                                        "{ content: 'Download Document', container: false, classes: ['tooltip'] }",
                                      modifiers: { "left-start": true },
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.getDocumentPath(
                                          props.dataItem
                                        ),
                                        download: "",
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-download" })]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.left-start",
                                      value: {
                                        content: "View Document",
                                        container: false,
                                        classes: ["tooltip"],
                                      },
                                      expression:
                                        "{ content: 'View Document', container: false, classes: ['tooltip'] }",
                                      modifiers: { "left-start": true },
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.viewDocument(
                                            props.dataItem
                                          )
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-eye" })]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.left-start",
                                      value: {
                                        content: "Delete Document",
                                        container: false,
                                        classes: ["tooltip"],
                                      },
                                      expression:
                                        "{ content: 'Delete Document', container: false, classes: ['tooltip'] }",
                                      modifiers: { "left-start": true },
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      class: [
                                        "trash",
                                        !_vm.$hasPermissions(
                                          _vm.clientSession,
                                          ["DOCUMENT_CENTER"],
                                          2
                                        )
                                          ? "disabled"
                                          : "",
                                      ],
                                      attrs: { href: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          !_vm.$hasPermissions(
                                            _vm.clientSession,
                                            ["DOCUMENT_CENTER"],
                                            2
                                          )
                                            ? null
                                            : _vm.promptDelete(props.dataItem)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-trash" })]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "reportTypeFilterSlotTemplate",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c("div", { staticClass: "k-filtercell" }, [
                          _c("div", { staticClass: "k-filtercell-wrapper" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters[props.field],
                                  expression: "filters[props.field]",
                                },
                              ],
                              staticClass: "k-textbox",
                              attrs: { type: "text", id: "" + props.field },
                              domProps: { value: _vm.filters[props.field] },
                              on: {
                                blur: _vm.filterChangeHandler,
                                keyup: _vm.filterChangeHandler,
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.filters,
                                    props.field,
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _vm.showDeleteModal
        ? _c(
            "div",
            { staticClass: "modal is-active", attrs: { id: "delete-modal" } },
            [
              _c("div", {
                staticClass: "modal-background",
                on: {
                  click: function ($event) {
                    _vm.showDeleteModal = false
                  },
                },
              }),
              _c("div", { staticClass: "modal-card" }, [
                _c("header", { staticClass: "modal-card-head has-bg-danger" }, [
                  _c("div", { staticClass: "modal-card-title has-bg-danger" }, [
                    _vm._v("Confirm Delete"),
                  ]),
                  _c("a", {
                    staticClass: "delete",
                    attrs: { "aria-label": "close" },
                    on: {
                      click: function ($event) {
                        _vm.showDeleteModal = false
                      },
                    },
                  }),
                ]),
                _c("section", { staticClass: "modal-card-body" }, [
                  _c("div", { staticClass: "content" }, [
                    _vm._v(' Type "'),
                    _c("b", [_vm._v("delete")]),
                    _vm._v(
                      '" to confirm that you want to delete "' +
                        _vm._s(_vm.selectedFile.name) +
                        '" '
                    ),
                  ]),
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          { name: "focus", rawName: "v-focus" },
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.confirmationText,
                            expression: "confirmationText",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "text",
                          placeholder: "type `delete` and then click confirm",
                        },
                        domProps: { value: _vm.confirmationText },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.deleteIfConfirmed.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.confirmationText = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("footer", { staticClass: "modal-card-foot" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button is-danger",
                      attrs: { disabled: _vm.confirmationText !== "delete" },
                      on: { click: _vm.deleteIfConfirmed },
                    },
                    [_vm._m(1), _c("span", [_vm._v("Confirm")])]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          _vm.showDeleteModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }